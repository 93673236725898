@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/lightmode';
@use 'sats-ui-lib/tokens/corner-radius';

.time-card {
  $breakpoint: 500px;
  background: lightmode.$background-surface-primary;
  padding: spacing.$s 0;
  border-radius: corner-radius.$s;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 spacing.$m;
  }

  &__splitter {
    margin-top: spacing.$s;
    margin-bottom: spacing.$m;
    border-bottom: 1px solid lightmode.$ui-border;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__times {
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
    padding: 0 spacing.$m;
  }

  &__empty {
    padding: 0 spacing.$m;
  }

  &__empty-description {
    margin-top: spacing.$m;
    color: lightmode.$on-surface-secondary;
  }

  &__description {
    &--orange-description {
      color: lightmode.$signal-warning-text;
    }
  }

  &__time {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: spacing.$xxs 0;
  }

  &__icon {
    display: flex;
    gap: spacing.$xs;
    align-items: center;
  }
}
